<script>
	import Base from '@backend/Base.vue';
	export default {
		name: "BoUserLevel",
		extends: Base,
		data() {
			return {
				Name: "BoUserLevel",
				mrMenuRole: {},
				mrModuleRole: {},
        dataLength: 0
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData(() => {
        if (!this.data.data.length > 0) {
          this.$router.push({name: this.Name})
        }
      })
      this.moduleRedirect()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="card" v-if="!$route.params.id">
			<div class="card-body">
				<VForm @resp="search">
					<div class="row gutter-10">
						<div class="col-sm-3">
							<h5 class="card-title">Daftar {{ObjectName}}</h5>
						</div>
						<div v-if="dataLength > 0" class="col-9 col-lg-3 col-sm-4 ml-auto">
							<div class="form-group mb-0">
								<div class="input-group">
									<input type="text" v-model="filter.search" class="form-control" placeholder="Cari...">
									<div class="input-group-append">
										<button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
									</div>
								</div>
							</div>
						</div>
						<div v-if="dataLength > 0" class="col-3 col-sm-auto">
							<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
						</div>
					</div>
				</VForm>
			</div>
			<div class="table-responsive ajax-table">
				<table class="table table-striped table-bordered">
					<thead>
						<tr>
							<th>#</th>
							<th>
								Nama Level
							</th>
							<th>{{fields.bul_is_active}}</th>
							<th width="120px" v-if="moduleRole('Edit') || moduleRole('Delete')">Aksi</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(v,k) in data.data" :key="k">
							<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
							<td>{{v.bul_name}}</td>
							<td>
								<StatusLabel :status="v.status"></StatusLabel>
							</td>
							<td class="btn-action" v-if="moduleRole('Edit') || moduleRole('Delete')">
								<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}"
									v-tooltip="'Ubah'"><i class="ti-marker-alt"></i></router-link>
								<a v-if="page.moduleRole('Edit')" href="javascript:;" class="icon_action"
									@click="changeStatus(k)" v-tooltip="'Ubah Status'"><i class="icon-settings"></i></a>
								<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
									@click="deleteItem($event,k)" v-tooltip="'Hapus'"><i class="ti-trash"></i></a>
							</td>
						</tr>
						<tr v-if="NotFound">
							<td colspan="99">
								<h5 class="tc">{{NotFound}}</h5>
							</td>
						</tr>
						<tr v-if="data.data===false">
							<td colspan="99">
								<LoadingSpinner light></LoadingSpinner>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="card-body">
				<Pagination class="justify-content-end m-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{row.id?"Ubah":"Tambah"}} {{ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="bul_name" v-bind="validation('bul_name')" v-model="row.bul_name" :label="'Nama Level'" :attr="{placeholder: 'e.g Content Creator'}"></BoField>
							<BoField name="bul_is_active">
								<div class="row">
									<div class="col-2">
										<radio name="bul_is_active" v-model="row.bul_is_active" option="Y"
											:attr="validation('bul_is_active')">Active</radio>
									</div>
									<div class="col-2">
										<radio name="bul_is_active" v-model="row.bul_is_active" option="N">Inactive</radio>
									</div>
								</div>
							</BoField>
						</div>

						<div class="col-sm-6">
							<BoField name="bul_menu_role" :label="'Menu level'">
								<select2 name="bul_menu_role" v-bind="validation('bul_menu_role')" :options="mrMenuRole"
									v-model="row.bul_menu_role" multiple></select2>
							</BoField>

							<BoField name="bul_module_role" :label="'Modul Level'">
								<select2 name="bul_module_role" v-bind="validation('bul_module_role')" :options="mrModuleRole"
									v-model="row.bul_module_role" multiple></select2>
							</BoField>
						</div>

						<div class="col-12">
							<div class="text-right">
                <router-link
                  :to="{name: $route.name}"
                  class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                >
                  Kembali
                </router-link>
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">{{$route.params.id=='add'?'Tambah':'Ubah'}}</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>